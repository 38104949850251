@tailwind base;
@tailwind components;
@tailwind utilities;

@import "./components/button.css";
@import "./components/form.css";
@import "./components/typography.css";

html {
  @apply text-base font-normal antialiased bg-ice;
}

body {
  @apply bg-ice;
}
