h1 {
  font-size: 3rem;
}

h2 {
  font-size: 2.5rem;
}

h3 {
  font-size: 2rem;
}

h4 {
  font-size: 1.5rem;
}

h5 {
  font-size: 1.25rem;
}

p {
  font-size: 1rem;
}

p.caption {
  @apply text-gray;
  font-size: 0.75rem;
}
